.carousel_cards {
  display: flex;
  gap: 6rem;
  overflow-y: scroll;
  padding: 30rem 6rem 0 2rem;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.carousel_cards::-webkit-scrollbar {
  display: none;
}

@media (max-width: 943px) {
  .carousel_cards {
    padding: 25rem 10rem 0 5rem;
    gap: 10rem;
    margin-left: 10rem;
  }
}
@media (max-width: 943px) {
  .carousel_cards {
    padding: 25rem 10rem 0 10rem;
    gap: 10rem;
    margin-left: unset;
  }
}

@media (max-width: 599px) {
  .carousel_cards {
    padding: 25rem 8rem 0 8rem;
    gap: 8rem;
  }
}

@media (max-width: 343px) {
  .carousel_cards {
    padding: 15rem 0 0 2rem;
  }
}
