.section_section1_main {
  grid-column: 2;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-self: center;
}

.link_wrapper {
  width: 22rem;
}

.section_section1_a {
  padding: 1.6rem 2rem 1.6rem 2rem;
  background: #b0282e;
  border-radius: 3px;
  color: white;
  text-decoration: none;
  font-size: 1.8rem;
  font-weight: bold;
  width: 22rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #b0282e;
  background: linear-gradient(to left, #b0282f 50%, #0c0c0c 50%) right;
  background-size: 200%;
  transition: 0.2s ease-out;
}

.section_section1_a:hover,
.section_section1_a:active {
  animation: none;
  background-position: left;
}

.p_helper {
  padding: 8rem 35rem 0 0;
}

.section_section1_p {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 3rem;
  color: rgb(160, 160, 160);
  white-space: nowrap;
}

.section_section1_h2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 7.2rem;
  line-height: 10rem;
}

.section_section1_cards {
  grid-row: 1;
  grid-column: 1;
  justify-self: flex-start;
  align-self: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.cube_drag_text {
  font-size: 1.8rem;
  position: absolute;
  top: -8rem;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
}

.cube_icon {
  fill: #fff;
  width: 1.8rem;
}

@media (max-width: 1343px) {
  .cube_drag_text {
    top: -6rem;
  }
}

@media (max-width: 943px) {
  .section_section1_main {
    grid-column: 1;
    grid-row: 1;
  }

  .section_section1_cards {
    grid-row: 2;
    width: 70%;
    justify-self: center;
    align-self: center;
    height: 60rem;
  }
  .cube_drag_text {
    top: 4rem;
  }
  .section_section1_h2 {
    padding: 0 0 5rem 0;
    text-align: center;
  }
  .p_helper {
    padding: 0 0 0 0;
    justify-self: center;
    align-self: center;
    text-align: center;
  }
  .link_wrapper {
    align-self: center;
  }
}

@media (max-width: 703px) {
  .section_section1_h2 {
    padding: 0 10rem 5rem 10rem;
  }
}
@media (max-width: 599px) {
  .section_section1_h2 {
    font-size: 6.4rem;
    line-height: 8rem;
    padding: 0 5rem 5rem 5rem;
  }
}

@media (max-width: 459px) {
  .section_section1_h2 {
    font-size: 6.2rem;
    line-height: 7.6rem;
    padding: 0 2rem 5rem 8rem;
    text-align: left;
  }
}
@media (max-width: 343px) {
  .section_section1_h2 {
    padding: 0 2rem 5rem 2rem;
  }
}
