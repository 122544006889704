.wrapper {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  max-width: 40rem;
  z-index: 100000;
  padding: 2rem 2rem 6rem 2rem;
  font-size: 1.4rem;
  line-height: 2rem;
  border: 2px solid #b0282e;
  background-color: #0c0c0c;
}

.button_footer {
  cursor: pointer;
  text-decoration: underline;
}
.button_footer:hover {
  opacity: 1;
}

.accept_button {
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  cursor: pointer;
  text-decoration: underline;
}
