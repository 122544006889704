.canvas:hover {
  cursor: grab;
}

.canvas:active {
  cursor: grabbing;
}

canvas {
  touch-action: none;
}

@media (max-width: 943px) {
  .section_section1_main {
    grid-column: 1;
    grid-row: 1;
  }

  .section_section1_cards {
    grid-row: 2;
  }
}
