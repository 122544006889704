.container {
  display: grid;
  padding: 15rem 20rem 15rem 20rem;
  position: relative;
  row-gap: 20rem;
}

.heading {
  font-size: 8rem;
  line-height: 10rem;
  justify-self: center;
  text-align: center;
}

.categories {
  padding-right: 10rem;
  align-self: center;
  display: flex;
  flex-direction: column;
}

.all_posts {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 6rem;
}

@media (max-width: 1343px) {
  .all_posts {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 943px) {
  .container {
    padding: 15rem 15rem 15rem 15rem;
  }
  .all_posts {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 703px) {
  .container {
    padding: 15rem 20rem 15rem 20rem;
  }
  .all_posts {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 599px) {
  .container {
    padding: 15rem 10rem 15rem 8rem;
  }
}
@media (max-width: 459px) {
  .container {
    padding: 15rem 4rem 15rem 8rem;
  }
  .heading {
    font-size: 6.4rem;
    line-height: 8rem;
    justify-self: center;
    text-align: center;
  }
}
