.wrapper {
  height: 100vh;
  display: grid;
  padding: 0 0 0 10rem;
  grid-template-columns: 13fr 2fr 10fr;
  grid-template-rows: 1fr;
  position: relative;
}

.heading_website_dev {
  grid-column: 3;
  align-self: center;
  font-size: 9.6rem;
  line-height: 12rem;
  font-weight: 300;
  padding-left: 4rem;
}

.hashes {
  grid-column: 2;
  grid-row: 1;
  align-self: center;
  font-size: 2rem;
  line-height: 2.6rem;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(-40rem * 4));
  }
}

.slider {
  grid-column: 1;
  grid-row: 1;
  width: 80%;
  height: 100vh;
  margin: auto;
  overflow: hidden;
  position: relative;
}

.slide_track {
  animation: scroll 30s linear infinite;
  display: flex;
  flex-direction: column;
}

.slide {
  height: 40rem;
}

@media (max-width: 1699px) {
  .wrapper {
    padding: 0 4rem 0 10rem;
  }
  .slide {
    height: 36rem;
  }
  @keyframes scroll {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(calc(-36rem * 4));
    }
  }
}

@media (max-width: 1343px) {
  .heading_website_dev {
    font-size: 6rem;
    line-height: 8rem;
  }
}

@media (max-width: 943px) {
  .wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    padding: 10rem 5rem 0 10rem;
    row-gap: 10rem;
  }
  .heading_website_dev {
    font-size: 8rem;
    line-height: 9rem;
    grid-column: unset;
    grid-row: 1;
    padding: 0;
  }

  .slider {
    grid-row: 2;
    grid-column: span 2;
    height: 100%;
    width: 100%;
  }
  .slide {
    height: 50rem;
  }
  @keyframes scroll {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(calc(-50rem * 4));
    }
  }
}

@media (max-width: 703px) {
}
@media (max-width: 599px) {
  .wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    padding: 10rem 5rem 0 10rem;
    row-gap: 10rem;
  }
  .heading_website_dev {
    font-size: 8rem;
    line-height: 9rem;
    grid-column: unset;
    grid-row: 2;
    padding: 0;
  }

  .hashes {
    grid-row: 1;
    grid-column: unset;
  }

  .slider {
    grid-row: 3;
    grid-column: span 2;
    height: 100%;
    width: 100%;
  }
  .slide {
    height: 40rem;
  }
  @keyframes scroll {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(calc(-40rem * 4));
    }
  }
}

@media (max-width: 459px) {
  .wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    padding: 10rem 5rem 0 8rem;
    row-gap: 10rem;
  }
  .heading_website_dev {
    font-size: 5.4rem;
    line-height: 7rem;
  }

  .slide {
    height: 20rem;
  }
  @keyframes scroll {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(calc(-20rem * 4));
    }
  }
}

@media (max-width: 343px) {
  .wrapper {
    padding: 10rem 2rem 0 2rem;
  }
}
