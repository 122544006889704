.offer_wrapper {
  position: relative;
  padding: 30rem 10rem 0 20rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: min-content;
  row-gap: 8rem;
}

.offer_title {
  grid-row: 1;
  grid-column: 1 / 3;
  font-size: 6.4rem;
  font-weight: 400;
  line-height: 8rem;
}

.offer_title_span {
  font-size: 4rem;
  font-weight: 700;
}

.offer_link:link,
.offer_link:visited {
  align-self: center;
  text-decoration: none;
  width: 30rem;
  background: #b0282e;
  border-radius: 3px;
  color: white;
  text-decoration: none;
  font-size: 1.8rem;
  font-weight: bold;
  border: 3px solid #b0282e;
  background: linear-gradient(to left, #b0282f 50%, #0c0c0c 50%) right;
  background-size: 200%;
  transition: 0.2s ease-out;
}

.offer_link:hover,
.offer_link:active {
  animation: none;
  background-position: left;
}

.offer_link_wrapper {
  padding: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 2rem;
}

.card {
  display: flex;
  flex-shrink: 0;
  width: 40rem;
  height: 56rem;
  background-image: linear-gradient(180deg, #b0282e 0%, #0c0c0c 100%);
  border-radius: 6px;
  flex-direction: column;
  text-decoration: none;
  color: #fff;
  transform-style: preserve-3d;
}

.card_title {
  font-size: 2.4rem;
  align-self: center;
  padding: 4rem 0 0 0;
}

.card_excerpt {
  align-self: center;
  font-size: 1.6rem;
  padding: 2rem 2rem 0 2rem;
  text-align: center;
  line-height: 2rem;
}

.card_list_wrapper {
  padding: 6rem 4rem 0 4rem;
}

.card_list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.list_item {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.check_icon {
  width: 1.6rem;
}

.list_item_name {
  align-self: center;
  font-size: 1.4rem;
  font-weight: 400;
}

.card_price {
  align-self: center;
  padding: 6rem 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.price {
  font-size: 3rem;
  font-weight: 700;
}

.per_month {
  font-size: 1.4rem;
  font-weight: 400;
  padding-left: 1rem;
  vertical-align: middle;
}

.min_month {
  align-self: center;
  padding: 1rem 0 0 0;
}

@media (max-width: 1699px) {
  .card {
    width: 38rem;
  }
}

@media (max-width: 1343px) {
  .offer_wrapper {
    grid-template-columns: repeat(2, 1fr);
    padding: 30rem 10rem 0 15rem;
    justify-items: center;
  }
  .offer_title {
    grid-column: 1;
  }
  .premium_card {
    grid-column: span 2;
  }
}

@media (max-width: 943px) {
  .offer_wrapper {
    column-gap: 2rem;
    padding: 30rem 5rem 0 10rem;
  }
  .offer_title {
    grid-column: span 2;
    justify-self: flex-start;
  }
  .offer_link {
    display: none;
  }
  .card {
    width: 36rem;
  }
}

@media (max-width: 703px) {
  .offer_wrapper {
    grid-template-columns: 1fr;
    padding: 30rem 5rem 0 10rem;
    justify-items: center;
  }
  .offer_title {
    grid-column: 1;
    justify-self: flex-start;
  }
  .premium_card {
    grid-column: 1;
  }
}
@media (max-width: 599px) {
}

@media (max-width: 459px) {
  .offer_wrapper {
    padding: 20rem 4rem 0 8rem;
  }
  .offer_title {
    font-size: 5rem;
  }
  .offer_title_span {
    font-size: 3.2rem;
    font-weight: 700;
  }
  .card {
    width: 100%;
  }
}

@media (max-width: 343px) {
  .offer_wrapper {
    padding: 20rem 2rem 0 2rem;
  }

  .offer_title_span {
    font-size: 2.6rem;
    font-weight: 700;
  }
}
