@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@500;700;900&family=Roboto:wght@300;400;500;700;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scrollbar-color: #b0282e;
}

body {
  font-family: "Roboto", sans-serif;
  line-height: 1;
  font-weight: 400;
  background-color: #0c0c0c;
  color: #fff;
  cursor: default;
}

.animation_on_load {
  animation-duration: 1s;
  animation-name: fadeIn;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* STYLE FOR MAIN COMPONENTS */

.header {
  position: relative;
  z-index: 99;
}

.section_hero {
  padding: 0 0 0 20rem;
  display: grid;
  grid-template-columns: 10fr 18fr;
  position: relative;
}

.section_section1 {
  position: relative;
  display: grid;
  grid-template-columns: 15fr 10fr;
  padding: 40rem 20rem 0rem 20rem;
  column-gap: 2.2rem;
}

.section_references {
  position: relative;
  display: grid;
  grid-template-columns: 10fr 15fr;
  padding: 26rem 0rem 0rem 20rem;
}

.section_contact {
  position: relative;
}

.section_footer {
  position: relative;
  padding: 10rem 0 0 0;
}

.logo_homepage {
  max-width: 4rem;
  min-height: auto;
  position: fixed;
  top: 4rem;
  left: 0.5rem;
}

.footer {
  min-height: 100vh;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog_main {
  display: grid;
  row-gap: 2rem;
  padding: 0 20rem 0 20rem;
  position: relative;
}

@media (max-width: 1699px) {
  html {
    font-size: 56.25%;
  }

  .section_section1 {
    padding: 40rem 10rem 0rem 10rem;
    column-gap: 4rem;
  }
}

@media (max-width: 1343px) {
  html {
    font-size: 50%;
  }

  .section_hero {
    padding: 0 0 0 15rem;
  }

  .section_section1 {
    padding: 30rem 4rem 0rem 15rem;
    column-gap: 6rem;
  }
  .section_references {
    padding: 30rem 0 0 15rem;
    gap: 4rem;
  }
}

@media (max-width: 943px) {
  .section_hero {
    display: block;
    padding: 0;
  }
  .section_section1 {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    padding: 30rem 15rem 0 15rem;
  }
  .section_references {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    padding: 30rem 15rem 0 15rem;
  }
}

@media (max-width: 703px) {
  .section_contact {
    width: 100%;
    padding: 0;
  }
  .section_section1 {
    padding: 20rem 0 0 0;
  }

  .section_references {
    padding: 20rem 0 0 0;
  }
}
@media (max-width: 599px) {
  /* html {
    font-size: 35%;
  } */
  .section_contact {
    width: 100%;
    padding: 0;
  }
  .section_section1 {
    padding: 20rem 0 0 0;
  }

  .section_references {
    padding: 20rem 0 0 0;
  }
}

@media (max-width: 459px) {
  .section_references {
    padding: 20rem 0 10rem 0;
  }
}

@media (max-width: 343px) {
  .logo_homepage {
    display: none;
  }
}
