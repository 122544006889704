.error_message {
  font-family: "Roboto", sans-serif;
  font-size: 4rem;
}

.loading {
  font-size: 4rem;
}

.wrapper {
  display: grid;
}

.post_heading {
  font-size: 7.2rem;
  line-height: 8rem;
  text-align: center;
  padding: 0 20rem 10rem 20rem;
}

.info_about_post {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20rem;
  justify-self: center;
}

.display_flex_column {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 0 5rem 0;
  font-size: 1.4rem;
}

.author {
}
.category {
}
.date {
}

.single_post_image {
  justify-self: center;
  width: 60%;
  padding-bottom: 10rem;
}

.single_post_excerpt_div {
  justify-self: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 10rem;
}

.single_post_excerpt_title {
  font-size: 3.2rem;
}

.single_post_excerpt_content {
  font-size: 2.2rem;
  line-height: 3rem;
  padding: 0 20rem 0 20rem;
}

.content {
  padding: 0 20rem 0 20rem;
}

.post_content {
  padding: 10rem 20rem 10rem 20rem;
  border: 2px solid white;
  background-color: #0c0c0c;
}

.post_content_h3:first-child {
  padding: 0 0 4rem 0;
}
.post_content_h3 {
  font-size: 2.6rem;
  padding: 4rem 0 4rem 0;
}

.post_content_p {
  font-size: 2rem;
  line-height: 3rem;
}

.post_content_code {
  line-height: 3rem;
  font-size: 2rem;
}

.post_content_a {
  font-size: 2rem;
  line-height: 3rem;
  color: red;
}

.post_content_h4 {
  font-size: 2.2rem;
  padding: 4rem 0 4rem 0;
}

.post_content_img {
  padding: 4rem 0 4rem 0;
  max-width: 100%;
  height: auto;
}

@media (max-width: 1343px) {
  .post_content {
    padding: 10rem 10rem 10rem 10rem;
  }
}
@media (max-width: 943px) {
  .single_post_excerpt_content {
    padding: 0 10rem 0 10rem;
  }
  .content {
    padding: 0 10rem 0 10rem;
  }
}
@media (max-width: 703px) {
  .content {
    padding: 0;
  }
  .post_heading {
    padding: 0 0 10rem 0;
    font-size: 6.2rem;
  }
  .single_post_image {
    width: 80%;
  }
  .info_about_post {
    gap: 10rem;
  }
}
@media (max-width: 599px) {
  .post_content {
    padding: 10rem 5rem 10rem 5rem;
  }
  .single_post_excerpt_content {
    padding: 0 5rem 0 5rem;
  }

  .post_content_code {
    width: 26rem;
  }
}
@media (max-width: 459px) {
  .info_about_post {
    gap: 6rem;
  }
}
