.stick_on_scroll {
  position: sticky;
  top: 20rem;
}

.display_flex {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: flex-start;
  justify-content: flex-start;
}

.hero_main_grid_style {
  grid-column: 1;
  position: sticky;
  top: 10rem;
  justify-self: flex-start;
  align-self: start;
}

.hero_section_h1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 10rem;
  line-height: 12rem;
  padding: 2rem 0 0 0;
  top: 18rem;
  width: 60rem;
  height: 38rem;
}

.hero_section_p {
  grid-row: 2 / 2;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 3rem;
  color: rgb(160, 160, 160);
  padding: 4rem 0 0 0;
  top: 66rem;
}

.hero_section_a {
  grid-row: 3 / 3;
  grid-column: 1 / 1;
  padding: 1.6rem 2rem 1.6rem 2rem;
  background: #b0282e;
  border-radius: 3px;
  color: white;
  text-decoration: none;
  font-size: 1.8rem;
  font-weight: bold;
  width: 22rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #b0282e;
  background: linear-gradient(to left, #b0282f 50%, #0c0c0c 50%) right;
  background-size: 200%;
  transition: 0.2s ease-out;
}

.hero_section_a:hover,
.hero_section_a:active {
  animation: none;
  background-position: left;
}

.hero_section_cards {
  margin-left: 60rem;
  position: relative;
}

.animation_h1:hover {
  animation: move-up 0.2s;
}

@keyframes move-up {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 1343px) {
  .display_flex {
    gap: 2.6rem;
  }

  .hero_section_h1 {
    padding: 0;
    height: auto;
  }
  .hero_main_grid_style {
    position: relative;
  }
}

@media (max-width: 943px) {
  .hero_main_grid_style {
    align-self: center;
    justify-self: center;
    padding: 0rem 10rem 0 10rem;
  }

  .hero_main_grid_style::after {
    content: "Swipe Cards Left For More";
    color: aliceblue;
    position: absolute;
    bottom: -10rem;
    left: 10rem;
    font-family: "Roboto", sans-serif;
    font-size: 1.6rem;
    opacity: 0.5;
    font-weight: 500;
  }
}

@media (max-width: 703px) {
}

@media (max-width: 599px) {
  .hero_main_grid_style {
    padding: 0 0 0 8rem;
  }
  .hero_section_h1 {
    font-size: 9.2rem;
    line-height: 10rem;
    width: unset;
  }
  .hero_main_grid_style::after {
    left: 8rem;
  }
}

@media (max-width: 459px) {
  .hero_section_h1 {
    font-size: 8.4rem;
    line-height: 9.2rem;
    width: unset;
    padding: 0 2rem 0 0;
  }
}

@media (max-width: 343px) {
  .hero_main_grid_style {
    padding: 10rem 0 0 2rem;
  }

  .hero_main_grid_style::after {
    left: 2rem;
  }
}
