.container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 20rem 0 20rem;
}

.heading {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 10rem;
  line-height: 12rem;
  width: 100%;
  padding: 23rem 5rem 10rem 0;
}

.animation_h1:hover {
  animation: move-up 0.2s;
}

@keyframes move-up {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.excerpt {
  font-size: 2rem;
  line-height: 2.6rem;
  opacity: 0.7;
}

.memory_title {
  grid-column: 1 / -2;
  font-size: 1.6rem;
  font-weight: 700;
  padding: 2rem 0 2rem 0;
  line-height: 2rem;
}

.memory_grid {
  padding: 10rem 0 12rem 6rem;
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-template-rows: auto 1fr 1fr 1fr auto;
  gap: 2.6rem;
  perspective: 100%;
  position: relative;
}
.memory_button {
  padding: 1.4rem 6rem;
  font-weight: 700;
  font-size: 1.6rem;
  font-family: "Roboto", sans-serif;
  color: #fff;
  background: #b0282e;
  border-radius: 3px;
  text-decoration: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #b0282e;
  background: linear-gradient(to left, #b0282f 50%, #0c0c0c 50%) right;
  width: 22rem;
  background-size: 200%;
  transition: 0.2s ease-out;
}

.memory_button:hover {
  background-position: left;
  cursor: pointer;
}

.memory_info {
  grid-column: -2 / -1;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1.6rem;
  font-weight: 700;
  padding: 2rem 0 2rem 0;
  line-height: 2rem;
}

.memory_moves {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.memory_high_score {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: flex-end;
}

.button_reset {
  justify-self: center;
  grid-row: 5;
  grid-column: 1 / span 2;
  padding-top: 2rem;
}
.button_reveal {
  padding-top: 2rem;
  justify-self: center;
  grid-row: 5;
  grid-column: -3 / span 2;
}

@media (max-width: 1343px) {
  .container {
    padding: 0 10rem 0 10rem;
  }
  .memory_grid {
    padding: 10rem 0 12rem 0;
  }
}
@media (max-width: 1123px) {
  .container {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 703px) {
  .container {
    padding: 0 5rem 0 10rem;
  }
  .memory_grid {
    grid-template-columns: repeat(3, auto);
  }
  .button_reset {
    grid-row: 6;
    grid-column: span 3;
  }
  .button_reveal {
    grid-row: 7;
    grid-column: span 3;
  }
}
@media (max-width: 599px) {
  .container {
    padding: 0 5rem 0 7rem;
  }

  .heading {
    padding: 15rem 5rem 10rem 0;
  }
}
@media (max-width: 459px) {
  .container {
    padding: 0 5rem 0 7rem;
  }

  .heading {
    font-size: 7.2rem;
    line-height: 9rem;
    padding: 15rem 0 10rem 0;
  }
}
