.menu {
  background-color: #0c0c0c;
  width: 100%;
  height: 100vh;
  padding: 3rem 0 0 10rem;
  transform: translateX(-100%);
  transition: 1s;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

.menu_open {
  height: 100vh;
  transform: translateX(0%);
  overflow: hidden;
}

.menu_close {
  transform: translateX(-100%);
  transition: 1s;
}

.menu_list {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  list-style: none;
  padding-right: 80rem;
}

.menu_list_item_link:link,
.menu_list_item_link:visited {
  padding: 5rem 0 5rem 0;
  color: #4b4b4b;
  text-decoration: none;
  font-size: 5.6rem;
  display: flex;
  align-items: center;
}
.menu_list_item_link:hover,
.menu_list_item_link:active {
  color: #fff;
  text-decoration: none;
  font-size: 5.6rem;
}

.menu_list_footer {
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: 2rem;
  padding-top: 4rem;
}

.menu_list_footer_item:link,
.menu_list_footer_item:visited {
  text-decoration: none;
  color: #4b4b4b;
  font-size: 3rem;
  padding: 1rem 0 1rem 0;
}
.menu_list_footer_item:hover,
.menu_list_footer_item:active {
  text-decoration: none;
  color: #fff;
  padding: 1rem 0 1rem 0;
}

.menu_button_close {
  text-decoration: none;
  padding: 1.6rem 16rem 1.6rem 16rem;
  background-color: white;
  color: #b0282e;
  font-weight: bold;
  top: 40rem;
  right: -17rem;
  transform: rotate(90deg);
  border-radius: 3px;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  position: fixed;
}

.menu_button_close:hover,
.menu_button_close:active {
  color: black;
  cursor: pointer;
}

.menu_button_close:hover span {
  transform: scale(1.1);
}
.menu_button_close:hover svg {
  transform: scale(1.1);
}

.menu_icon {
  height: 2.2rem;
  width: 2.2rem;
}

.cards {
  display: none;
  position: absolute;
  top: 10%;
  left: 60%;
}

.menu_list_item_link:hover + .cards {
  display: block;
}

.cards_ul {
  list-style: none;
}

.logo_menu {
  position: absolute;
  top: 13%;
  left: 60%;
}
.logo_menu_img {
  width: 38rem;
  height: auto;
}

.active {
  color: white !important;
}

.active::before {
  content: "\f054";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  display: inline-block;
  font-size: 3rem;
  padding-right: 2rem;
  vertical-align: middle;
}

.active1 {
  color: white !important;
}

.active1::before {
  content: "\f054";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  display: inline-block;
  font-size: 2rem;
  padding-right: 2rem;
  vertical-align: middle;
}

@media (max-width: 1699px) {
  .cards {
    display: none;
    position: absolute;
    top: 10%;
    left: 67%;
  }

  .logo_menu {
    position: absolute;
    top: 13%;
    left: 67%;
  }
}
@media (max-width: 1343px) {
  .menu {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .menu_list_item_link:hover + .cards {
    display: none;
  }

  .menu_list_item_link:link,
  .menu_list_item_link:visited {
    padding: 2rem 0 2rem 0;
  }

  .menu_list {
    gap: 2rem;
    padding-right: 0;
  }

  .logo_menu_img {
    width: 14rem;
  }

  .menu_list_footer {
    position: relative;
  }

  .logo_menu {
    position: absolute;
    top: 4rem;
    left: 60%;
  }
}

@media (max-width: 943px) {
  .menu_list {
    padding-right: 10rem;
  }
}

@media (max-width: 703px) {
  .menu {
    padding: 6rem 0 0 5rem;
  }
}

@media (max-width: 599px) {
  .menu_list_item_link:link,
  .menu_list_item_link:visited {
    font-size: 4.6rem;
  }
}

@media (max-width: 459px) {
  .menu_list {
    gap: 0;
  }
  .menu_list_item_link:link,
  .menu_list_item_link:visited {
    font-size: 4rem;
  }
  .logo_menu_img {
    width: 10rem;
  }
  .logo_menu {
    left: 70%;
  }
}

@media (max-width: 343px) {
  .menu {
    padding: 2rem 0 0 2rem;
    transform: translateY(-100%);
  }

  .logo_menu_img {
    width: 8rem;
  }
  .logo_menu {
    position: absolute;
    top: 4rem;
    left: 70%;
  }
  .menu_open {
    height: 100vh;
    transform: translateY(0%);
    overflow: hidden;
  }

  .menu_close {
    transform: translateY(-100%);
    transition: 1s;
  }

  .menu_button_close {
    top: 95%;
    right: 0;
    transform: rotate(0deg);
    padding: 1.6rem 0 1.6rem 0;
    width: 100%;
  }
  .menu_list_item_link:link,
  .menu_list_item_link:visited {
    font-size: 3.2rem;
    padding: 1rem 0 1rem 0;
  }

  .menu_list_footer_item:link,
  .menu_list_footer_item:visited {
    font-size: 2.2rem;
  }

  .menu_list_footer {
    gap: 1rem;
  }
}
