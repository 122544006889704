.open_modal {
  background-color: #fff;
  height: 10rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 100;
  color: #0c0c0c;
  overflow-y: scroll;
  scrollbar-width: none;
  border-radius: 3px;
  border: 3px solid #b0282e;
  display: flex;
  justify-content: center;
  align-items: center;
}

.open_modal::-webkit-scrollbar {
  display: none;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99;
  background: rgba(0, 0, 0, 0.75);
}

.modal_button {
  position: absolute;
  right: 4rem;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.close {
  width: 2.6rem;
  stroke: #0c0c0c;
}

.modal_content {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  align-items: center;
}

.modal_h1 {
  font-size: 2.6rem;
  font-weight: 700;
  text-align: center;
  justify-self: center;
  align-self: center;
}

@media (max-width: 599px) {
  .modal_h1 {
    font-size: 2rem;
  }
  .modal_button {
    right: 2rem;
  }
}
