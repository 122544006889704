* {
  scrollbar-width: thick; /* none | auto  */
  scrollbar-color: #b0282e transparent;
}
html {
  font-size: 62.5%;
  scrollbar-color: #b0282e;
}

body {
  background-color: #1c1c1c;
}

.loader {
  display: inline-block;
  width: 80px;
  height: 80px;
  border: 4px solid #b0282e;
  top: 50%;
  animation: loader 2s infinite ease;
}

.loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: #b0282e;
  animation: loader-inner 2s infinite ease-in;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

.reset_position {
  position: relative;
}

::-webkit-scrollbar {
  width: auto;
}

::-webkit-scrollbar-track {
}

::-webkit-scrollbar-thumb {
  background: #b0282e;
}
