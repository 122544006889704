.menu_button {
  text-decoration: none;
  padding: 1.6rem 16rem 1.6rem 16rem;
  background-color: #fff;
  color: #b0282e;
  font-weight: bold;
  top: 40rem;
  left: -18rem;
  transform: rotate(90deg);
  border-radius: 3px;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  position: fixed;
}

.menu_button:hover,
.menu_button:active {
  color: black;
  cursor: pointer;
}

.menu_button:hover span {
  transform: scale(1.1);
}
.menu_button:hover svg {
  transform: scale(1.1);
}
.menu_icon {
  height: 2.2rem;
  width: 2.2rem;
}

@media (max-width: 703px) {
}

@media (max-width: 343px) {
  .menu_button {
    transform: rotate(0deg);
    top: 0;
    left: 0;
    padding: 1.6rem 0 1.6rem 0;
    width: 100%;
  }
}
