.wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.error {
  font-size: 2rem;
}

.number {
  font-size: 8rem;
}

.page {
  font-size: 1.6rem;
}
