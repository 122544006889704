.post {
  min-width: 100%;
  background-image: linear-gradient(180deg, #b0282e 0%, #0c0c0c 100%);
  border-radius: 3px;
}

.post_link:link,
.post_link:visited {
  text-decoration: none;
}
.post_link:hover,
.post_link:active {
}

.post_link:hover .read_more .center {
  opacity: 1;
}
.post_link:active .read_more .center {
  padding: 8px 10px 6px;
}

.read_more {
  position: relative;
}

.post_image {
  width: 100%;
  height: 35rem;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border: 2px solid #fff;
}

.center {
  position: absolute;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  height: 100%;
  top: 0;
}

.read_more_heading {
  background-color: #b0282e;
  color: #fff;
  font-size: 4.2rem;
  padding: 4rem 4rem 4rem 4rem;
  border-radius: 3px;
}

.post_title {
  color: #fff;
  text-align: center;
  font-size: 3.2rem;
  padding: 4rem 4rem 4rem 4rem;
}

.excerpt {
  color: #fff;
  font-size: 1.6rem;
  padding: 0 4rem 4rem 4rem;
  margin: auto;
  line-height: 2rem;
}

.error_message {
  font-family: "Roboto", sans-serif;
  font-size: 4rem;
  grid-column: span 2;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  grid-column: 2;
}

.info {
  display: flex;
  gap: 4rem;
  color: #fff;
  padding: 2rem 0 0 4rem;
}

.category {
  font-size: 1.2rem;
}

.date {
  font-size: 1.2rem;
}
