.link_wrapper {
  display: flex;
}

.link:link,
.link:visited {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #fff;
  padding: 2rem 130rem 2rem 2rem;
  z-index: 10;
}

.link:hover,
.link:active {
  color: #fff;
  z-index: 10;
}

.link:hover .span,
.link:active .span {
  opacity: 0;
  animation-duration: 0.3s;
  animation-name: removeNum;
  color: #fff;
}
.link:hover .name,
.link:active .name {
  animation-duration: 1s;
  animation-name: moveName;
  padding-left: 4rem;
}
.link:hover .hash,
.link:active .hash {
  animation-duration: 1s;
  animation-name: moveName;
  padding-left: 4rem;
}

.link_wrapper:hover img {
  animation-duration: 1s;
  animation-name: showImg;
  opacity: 1;
}

.span {
  font-size: 1.2rem;
}

.name {
  font-size: 4rem;
  padding-left: 2rem;
}

.hash {
  font-size: 1.4rem;
  padding-left: 2rem;
}

.image {
  opacity: 0;
  position: absolute;
  top: 10%;
  right: 10%;
  width: 50%;
}

@keyframes showImg {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }
  60% {
    transform: translateX(-30px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes removeNum {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    padding-left: 2rem;
  }
}

@keyframes moveName {
  0% {
    padding-left: 2rem;
  }

  100% {
    padding-left: 4rem;
  }
}

.loading {
  font-family: "Roboto", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
}

.error_message {
  font-family: "Roboto", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
}

@media (max-width: 1343px) {
  .link_wrapper {
    display: grid;
    grid-template-rows: auto auto;
    row-gap: 4rem;
  }

  .link:link,
  .link:visited {
    padding: 0 0 2rem 0;
  }
  .image {
    opacity: 1;
    position: unset;
    width: 100%;
    align-self: flex-end;
  }
  .link:hover .span,
  .link:active .span {
    opacity: 1;
    animation: none;
  }
  .link:hover .name,
  .link:active .name {
    animation: none;
    padding-left: 0;
  }
  .link:hover .hash,
  .link:active .hash {
    animation: none;
    padding-left: 0;
  }

  .link_wrapper:hover img {
    animation: none;
    opacity: 1;
  }
  .name {
    padding-left: 0;
  }

  .hash {
    padding-left: 0;
  }
}

@media (max-width: 943px) {
}

@media (max-width: 703px) {
}
@media (max-width: 599px) {
}
