.form {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 4rem 4rem 4rem 4rem;
  background: #0c0c0c;
  border: 2px solid #b0282e;
  border-radius: 2px;
  position: relative;
  z-index: 0;
}

.captcha {
  display: none;
}

.basic_info {
  display: flex;
  gap: 6rem;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  transition: background-color 5000s ease-in-out 0s;
}

.contact_name {
  width: 40rem;
  position: relative;
}

.input_name {
  width: 100%;
  border: 0;
  border-bottom: 2px solid gray;
  outline: 0;
  font-size: 2rem;
  color: white;
  padding: 1rem 0;
  background: transparent;
  transition: border-color 0.2s;
}

.input_name::placeholder {
  color: transparent;
  display: none;
}

.input_name:placeholder-shown ~ .label_name {
  font-size: 2rem;
  cursor: text;
  top: 1rem;
}

.label_name {
  position: absolute;
  top: -1rem;
  display: block;
  transition: 0.2s;
  font-size: 1.2rem;
  color: gray;
  z-index: -1;
}

.input_name:focus ~ .label_name {
  position: absolute;
  top: -1rem;
  display: block;
  transition: 0.2s;
  font-size: 1.2rem;
  color: grey;
}

.input_name:focus {
  padding-bottom: 1rem;
  border-bottom: 2px solid #b0282e;
}

.contact_message {
  width: 100%;
  position: relative;
}

.input_message {
  font-family: "Roboto", sans-serif;
  width: 100%;
  border: 0;
  border-bottom: 2px solid gray;
  outline: 0;
  font-size: 2rem;
  color: white;
  padding: 1rem 0;
  background: transparent;
  transition: border-color 0.2s;
  resize: none;
}

.input_message::placeholder {
  color: transparent;
}

.input_message:placeholder-shown ~ .label_message {
  font-size: 2rem;
  cursor: text;
  top: 1rem;
}

.label_message {
  position: absolute;
  top: -1rem;
  display: block;
  transition: 0.2s;
  font-size: 1.2rem;
  color: gray;
  z-index: -1;
}

.input_message:focus ~ .label_message {
  position: absolute;
  top: -2rem;
  display: block;
  transition: 0.2s;
  font-size: 1.2rem;
  color: grey;
}

.input_message:focus {
  padding-bottom: 1rem;
  border-bottom: 2px solid #b0282e;
}

.contact_checkbox {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(4, 1fr);
  font-size: 2rem;
  color: grey;
  padding-bottom: 2rem;
}

.contact_checkbox > span {
  grid-column: 1 /-1;
  align-self: center;
  padding: 0 0 2rem 0;
}

.checkbox_item {
  position: relative;
  width: 14rem;
  height: 10rem;
  border: 2px solid #b0282e;
  box-sizing: border-box;
  justify-self: center;
  border-radius: 2px;
}

.checkbox_input {
  position: absolute;
  top: 0;
  left: 0;
  width: 14rem;
  height: 10rem;
  opacity: 0;
  cursor: pointer;
}

.checkbox_input:checked ~ .label_check {
  background-color: #b0282e;
  color: white;
}

.label_check {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 2.6rem;
  transition: 0.5s ease;
  text-align: center;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.submit_button {
  width: 100%;
  padding: 1.6rem 2rem 1.6rem 2rem;
  background: #0c0c0c;
  border-radius: 3px;
  color: white;
  text-decoration: none;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #b0282e;
  background: linear-gradient(to left, #0c0c0c 50%, #b0282e 50%) right;
  background-size: 200%;
  transition: 0.2s ease-out;
  cursor: pointer;
}

.submit_button:hover,
.submit_button:active {
  animation: none;
  background-position: left;
}
.clear_button {
  width: 100%;
  padding: 1.6rem 2rem 1.6rem 2rem;
  background: #0c0c0c;
  border-radius: 3px;
  color: white;
  text-decoration: none;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #b0282e;
  cursor: pointer;
}

.clear_button:hover,
.clear_button:active {
  animation: none;
  background-position: left;
}

.test {
  position: fixed;
  top: 0;
  width: 10rem;
  height: 10rem;
  background-color: aliceblue;
}

.newsletter {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.3rem;
  color: grey;
}

@media (max-width: 943px) {
  .basic_info {
    flex-direction: column;
  }
  .contact_checkbox {
    column-gap: 1rem;
  }
  .basic_info {
    gap: 4rem;
  }
  .contact_name {
    width: 100%;
  }
}

@media (max-width: 703px) {
  .contact_checkbox {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2rem;
  }
}

@media (max-width: 459px) {
  .form {
    padding: 4rem 2rem 2rem 2rem;
  }
}
