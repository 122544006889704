.card {
  display: flex;
  flex-shrink: 0;
  width: 40rem;
  height: 54rem;
  background-image: linear-gradient(180deg, #b0282e 0%, #0c0c0c 100%);
  border-radius: 6px;
  flex-direction: column;
  text-decoration: none;
  color: #fff;

  transform-style: preserve-3d;
}

.card span {
  font-size: 2rem;
  padding: 4rem 0 0 4rem;
  font-weight: bold;
}

.card h2 {
  padding: 4rem 0 0 4rem;
  font-size: 4.2rem;
  font-weight: 300;
  line-height: 6rem;
  transform: translateZ(2rem);
}
.card h2:active {
  transform: translateZ(0);
}

.card h2 strong {
  font-weight: 900;
  transform: translateZ(4rem);
}

.card h3 {
  padding: 14rem 0 0 4rem;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.2rem;
}

@media (max-width: 459px) {
  .card {
    width: 35rem;
  }
}
