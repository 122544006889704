.open_modal {
  background-color: #fff;
  height: 90vh;
  width: 80%;
  position: absolute;
  bottom: 4rem;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 100;
  color: #0c0c0c;
  padding: 0 20rem 10rem 20rem;
  overflow-y: scroll;
  scrollbar-width: none;
  border-radius: 3px;
  border: 3px solid #b0282e;
}

.open_modal::-webkit-scrollbar {
  display: none;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99;
  background: rgba(0, 0, 0, 0.75);
}

.modal_button {
  position: absolute;
  top: 4rem;
  right: 4rem;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.close {
  width: 2.6rem;
  stroke: #0c0c0c;
}

.modal_content {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  align-items: center;
}

.modal_h1 {
  font-size: 4.2rem;
  font-weight: 900;
  padding: 10rem 0 5rem 0;
  text-align: center;
}

.modal_h2 {
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 3rem;
  text-align: center;
}

.modal_h3 {
  font-size: 2rem;
  font-weight: 700;
}

.modal_p {
  font-size: 1.6rem;
  line-height: 2.2rem;
}

@media (max-width: 599px) {
  .open_modal {
    padding: 0 5rem 10rem 5rem;
  }
}
