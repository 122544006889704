.contact_us_main {
  width: 100%;
  padding: 10rem 20rem 5rem 20rem;
  position: relative;
}

.contact_us_title {
  grid-column: 1 / -1;
  justify-self: center;
  font-size: 7.2rem;
  line-height: 10rem;
  text-align: center;
}

.contact_footer_main {
  display: grid;
  padding: 10rem 0 0 10rem;
  grid-template-columns: 2fr 3fr;
}

.contact_h3 {
  grid-column: 1;
  font-size: 4.2rem;
  line-height: 5rem;
  align-self: center;
}

.contact_us_content {
  grid-row: 2;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  font-size: 2.6rem;
  font-weight: bold;
}

.email_address:link,
.email_address:visited {
  color: #fff;
  text-decoration: none;
}

.email_address:hover,
.email_address:active {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.3);
}

.phone:link,
.phone:visited {
  text-decoration: none;
  color: #fff;
}

.phone:hover,
.phone:active {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.3);
}

.socialmedia {
  display: flex;
  gap: 4rem;
}

.logo:link,
.logo:visited {
  width: 4rem;
  height: 4rem;
  text-decoration: none;
  fill: #fff;
  border-radius: 10px;
}

.logo:hover,
.logo:active {
  width: 4rem;
  height: 4rem;
  text-decoration: none;
  fill: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
}

.contact_form {
  grid-column: 2;
  grid-row: 1 / 4;
  justify-self: center;
}

.legal {
  padding: 4rem 0 0 0;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
}

.button_footer {
  font-size: 1.4rem;
  background: none;
  border: none;
  color: #fff;
  display: flex;
  cursor: pointer;
  padding: 1rem 0 1rem 0;
  opacity: 0.8;
  width: max-content;
}
.button_footer:hover {
  opacity: 1;
}

.copyright {
  font-size: 1.4rem;
  padding: 0 0 1rem 0;
}

@media (max-width: 1699px) {
  .contact_us_main {
    padding: 5rem 10rem 5rem 10rem;
  }

  .contact_footer_main {
    column-gap: 6rem;
    padding: 10rem 0 5rem 5rem;
  }
}

@media (max-width: 1343px) {
  .contact_footer_main {
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 1fr;
  }
  .contact_h3 {
    display: none;
  }
  .contact_us_content {
    align-items: center;
    grid-row: 1;
    padding: 0 0 5rem 0;
    justify-self: center;
    align-self: center;
  }
  .contact_form {
    grid-column: 1;
    grid-row: 2;
  }
  .legal {
    justify-self: center;
    align-items: center;
  }
}

@media (max-width: 943px) {
  .contact_us_main {
    padding: 20rem 10rem 5rem 10rem;
  }
}

@media (max-width: 703px) {
  .contact_us_main {
    padding: 20rem 5rem 5rem 10rem;
  }

  .contact_footer_main {
    padding: 10rem 0 0 0;
  }
}
@media (max-width: 599px) {
  .contact_us_main {
    padding: 20rem 10rem 5rem 10rem;
  }

  .contact_footer_main {
    padding: 10rem 0 0 0;
  }

  .contact_us_title {
    font-size: 6.4rem;
    line-height: 8rem;
  }
}

@media (max-width: 459px) {
  .contact_us_main {
    padding: 10rem 0 5rem 0;
  }

  .contact_us_title {
    font-size: 6.2rem;
    line-height: 7.6rem;
    padding: 0 2rem 5rem 8rem;
    text-align: left;
  }
  .contact_footer_main {
    padding: 10rem 2rem 0 6rem;
  }
}

@media (max-width: 343px) {
  .contact_us_title {
    padding: 10rem 0 0 2rem;
  }

  .contact_footer_main {
    padding: 10rem 2rem 5rem 2rem;
  }
}
