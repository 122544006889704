.get_link {
  position: fixed;
  top: 3rem;
  right: 3rem;
  border: 3px solid #b0282e;
  background-color: #0c0c0c;
  color: white;
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: bold;
  width: 16rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: shake-button;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-delay: 0s;
  background: linear-gradient(to left, #0c0c0c 50%, #b0282f 50%) right;
  background-size: 200%;
  transition: 0.2s ease-out;
}

.get_link:hover,
.get_link:active {
  animation: none;
  background-position: left;
}

.get_link:active > .link_span {
  padding: 8px 10px 6px;
}

@keyframes shake-button {
  0% {
    transform: scale(1, 1);
    box-shadow: 0px 0px 30px rgba(176, 40, 47, 0.3);
  }
  2%,
  8% {
    transform: scale(1.1, 1.1);
    box-shadow: 0px 0px 10px hsla(357, 63%, 42%, 0.5);
  }
  4%,
  10% {
    transform: scale(1, 1);
    box-shadow: 0px 0px 30px rgba(176, 40, 47, 0.3);
  }
  20% {
    transform: none;
    box-shadow: none;
  }
}

@media (max-width: 703px) {
}

@media (max-width: 343px) {
  .get_link {
    display: none;
  }
}
