.portfolio_wrapper {
  height: 100vh;
  padding: 10rem 0 5rem 0;
  position: relative;
}

.heading_wrapper {
  display: flex;
  justify-content: center;
  padding: 0 0 8rem 0;
  position: relative;
}

.portfolio_h2 {
  font-size: 8rem;
}

.pages_wrapper {
  padding: 0 20rem 0 30rem;
}

.position {
  position: relative;
  overflow-x: hidden;
}

.link_wrapper {
  list-style: none;
  display: flex;
  flex-direction: column;
  height: 65vh;
  margin-left: 30rem;
}

.link_wrapper:hover > *:not(:hover) > a {
  color: rgba(255, 255, 255, 0.3);
}

.link_wrapper {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.link_wrapper::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.headings_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

@media (max-width: 1699px) {
}

@media (max-width: 1343px) {
  .portfolio_wrapper {
    padding: 30rem 0 5rem 0;
    height: unset;
  }
  .link_wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-left: 0;
    margin: 0 20rem 0 20rem;
    align-items: center;
    row-gap: 10rem;
    column-gap: 8rem;
  }
  .link_wrapper:hover > *:not(:hover) > a {
    color: #fff;
  }
  .position {
    padding: 0 0 5rem 0;
  }
}

@media (max-width: 943px) {
  .portfolio_wrapper {
    padding: 20rem 0 5rem 0;
  }
  .link_wrapper {
    margin: 0 15rem 0 15rem;
  }
}

@media (max-width: 703px) {
  .portfolio_wrapper {
    padding: 20rem 0 0 0;
  }
  .link_wrapper {
    grid-template-columns: 1fr;
    margin: 0 10rem 0 10rem;
  }
}
@media (max-width: 599px) {
}

@media (max-width: 459px) {
  .portfolio_h2 {
    font-size: 5.4rem;
  }
}
@media (max-width: 343px) {
  .link_wrapper {
    grid-template-columns: 1fr;
    margin: 0 2rem 0 2rem;
  }
}
