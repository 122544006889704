.logo_img {
  width: 20rem;
  height: auto;
}

.logo_link:link,
.logo_link:visited {
  left: 20rem;
}

.logo_link:hover,
.logo_link:active {
  left: 20rem;
  z-index: 2;
}

.logo_wrapper {
  width: 20rem;
  height: 3.2rem;
  padding: 0 0 8rem 0;
}
