.main_wrapper {
  padding: 15rem 20rem 15rem 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.link_back::before {
  content: "\f053";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  display: inline-block;
  font-size: 1.2rem;
  padding-right: 1rem;
  vertical-align: middle;
}

.link_back:link,
.link_back:visited {
  text-decoration: none;
  color: #fff;
  position: absolute;
  top: 5rem;
  left: 15%;
  font-size: 1.6rem;
}

@media (max-width: 1343px) {
}
@media (max-width: 943px) {
  .main_wrapper {
    padding: 15rem 15rem 15rem 15rem;
  }
}
@media (max-width: 943px) {
  .main_wrapper {
    padding: 15rem 5rem 15rem 5rem;
  }
}
@media (max-width: 703px) {
  .main_wrapper {
    padding: 15rem 5rem 15rem 10rem;
  }
}
@media (max-width: 599px) {
}
@media (max-width: 459px) {
  .main_wrapper {
    padding: 15rem 4rem 15rem 8rem;
  }
}
