.card {
  width: 16rem;
  height: 16rem;
  border-radius: 4px;
  transition: 0.3s;
  transform-style: preserve-3d;
  position: relative;
  cursor: pointer;

  justify-self: center;
}

.card_face {
  background: #b0282e;
  backface-visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}

.card_back_face {
  position: absolute;
  backface-visibility: hidden;
  transform: rotateY(180deg);
}

.is_flipped {
  transform: rotateY(180deg);
}

.is_inactive {
  transform: rotateY(180deg);
  opacity: 1;
}

.card img {
  width: 100%;
  height: 100%;
}

@media (max-width: 703px) {
}

@media (max-width: 599px) {
  .card {
    width: 14rem;
    height: 14rem;
  }
}
@media (max-width: 459px) {
  .card {
    width: 10rem;
    height: 10rem;
  }
}
