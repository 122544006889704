.container {
  position: relative;
  height: auto;
  width: 80rem;
  flex-shrink: 0;
}

.title_link {
  display: flex;
  flex-direction: column;
  color: white;
  text-decoration: none;
  font-size: 3rem;
  gap: 3rem;
}

.first_title {
  position: absolute;
  top: 48rem;
  left: 36rem;
}

.second_title {
  position: absolute;
  top: 28rem;
  left: 36rem;
}

.third_title {
  position: absolute;
  top: 5rem;
  left: 36rem;
}

.number {
  font-size: 1.2rem;
  font-weight: bold;
}

.title {
  font-weight: bold;
}

.hashes {
  font-size: 1.8rem;
  line-height: 2.2rem;
}

@media (max-width: 1699px) {
  .container {
    width: 84rem;
  }

  .title_link {
    padding-left: 10rem;
  }

  .first_title {
    position: absolute;
    top: 45rem;
    left: 34rem;
  }

  .second_title {
    position: absolute;
    top: 22rem;
    left: 36rem;
  }

  .third_title {
    position: absolute;
    top: 2rem;
    left: 40rem;
  }
}
