.list_of_references {
  position: relative;
  height: auto;
  width: 80rem;
  flex-shrink: 0;
  text-decoration: none;
}

.reference_img {
  width: 80rem;
}

.reference_container {
  display: flex;
  gap: 10rem;
  overflow: scroll;
  padding: 0 5rem 0 6rem;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.reference_container::-webkit-scrollbar {
  display: none;
}

.title_link {
  display: flex;
  flex-direction: column;
  color: white;
  text-decoration: none;
  font-size: 3rem;
  gap: 3rem;
  position: absolute;
  top: 55%;
  left: 35%;
}

.number {
  font-size: 1.2rem;
  font-weight: bold;
}

.title {
  font-weight: bold;
}

.hashes {
  font-size: 1.8rem;
  line-height: 2.2rem;
}

@media (max-width: 1699px) {
  .reference_img {
    width: 70rem;
  }
}

@media (max-width: 1343px) {
  .reference_img {
    width: 60rem;
    height: auto;
  }
  .list_of_references {
    width: auto;
  }
  .reference_container {
    padding: 0 5rem 0 0;
  }
}

@media (max-width: 943px) {
  .reference_container {
    padding: 10rem 4rem 0 4rem;
  }
  .title_link {
    padding: 4rem 0 0 0;
  }
}

@media (max-width: 599px) {
  .reference_img {
    width: 50rem;
  }

  .reference_container {
    padding: 10rem 2rem 0 6rem;
  }
  .title_link {
    padding: 4rem 0 0 0;
    gap: 2rem;
  }
}
@media (max-width: 459px) {
  .reference_img {
    width: 42rem;
  }

  .title_link {
    font-size: 2.4rem;
    gap: 1rem;
  }
}

@media (max-width: 343px) {
  .reference_container {
    padding: 10rem 0 0 2rem;
  }
}
