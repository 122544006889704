.stages_website_wrapper {
  padding: 25rem 10rem 0 20rem;
  height: 300vh;
}

.stay_on_scroll {
  position: sticky;
  top: 10rem;
}

.stages_h2 {
  font-size: 6.4rem;
  font-weight: 400;
}

.stages_grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(3, 1fr);
  padding-top: 10rem;
}

.card_main {
  background-color: #b0282e;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  line-height: 2.2rem;
}

@keyframes fadein_card {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.card1 {
  height: 26rem;
  width: 26rem;
  grid-column: 1;
  grid-row: 1;
  animation-name: fadein_card;
  animation-duration: 1s;
}

.card2 {
  height: 16rem;
  width: 16rem;
  grid-column: 2;
  grid-row: 2 / 4;
  align-self: center;
  animation-name: fadein_card;
  animation-duration: 1s;
}

.card3 {
  height: 20rem;
  width: 20rem;
  grid-column: 2 / 4;
  grid-row: 1 / 3;
  align-self: center;
  justify-self: center;
  animation-name: fadein_card;
  animation-duration: 1s;
}
.card4 {
  height: 24rem;
  width: 24rem;
  grid-column: 4;
  grid-row: 1;
  justify-self: start;
  animation-name: fadein_card;
  animation-duration: 1s;
}
.card5 {
  height: 20rem;
  width: 20rem;
  grid-column: 4 / 6;
  grid-row: 2 / 4;
  align-self: center;
  justify-self: center;
  animation-name: fadein_card;
  animation-duration: 1s;
}
.card6 {
  height: 30rem;
  width: 30rem;
  grid-column: 5/7;
  grid-row: 1 / 3;
  align-self: center;
  justify-self: center;
  margin-left: 10rem;
  animation-name: fadein_card;
  animation-duration: 1s;
}

.stage_name {
  font-size: 2rem;
  font-weight: bold;
}

.line_container {
  position: absolute;
  top: 20%;
  left: 10rem;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
  z-index: 1;
}

.svg_path_website {
  display: inline-block;
  z-index: 1;
}
