.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 30rem 20rem 0 20rem;

  position: relative;
  column-gap: 8rem;
  width: 100%;
  position: relative;
}

.card {
  justify-self: center;
  width: 100%;
  height: 60rem;
  background-image: linear-gradient(180deg, #b0282e 0%, #0c0c0c 100%);
  display: grid;
  grid-template-rows: 1fr 4fr 3fr;
  padding: 6rem 4rem 4rem 4rem;
  border-radius: 6px;
}

.pre {
  font-size: 1.6rem;
  font-weight: 400;
}

.name {
  letter-spacing: 2px;
  font-weight: 700;
}

.heading {
  font-size: 4rem;
  text-align: center;
  line-height: 4rem;
}

.heading_h2 {
  font-size: 8rem;
  padding-bottom: 10rem;
  grid-column: span 3;
}

.card_desc {
  text-align: center;
  font-size: 2rem;
  padding: 6rem 0 6rem 0;
  line-height: 2.6rem;
}

.icon {
  opacity: 0.7;
  color: #0c0c0c;
  justify-self: center;
  width: 8rem;
  height: 8rem;
}

.eye {
  width: 9rem;
  height: 9rem;
}

@media (max-width: 1343px) {
  .container {
    column-gap: 4rem;
    padding: 30rem 15rem 0 15rem;
  }
}
@media (max-width: 943px) {
  .container {
    column-gap: 4rem;
    padding: 30rem 10rem 0 10rem;
    grid-template-columns: 1fr 1fr;
    row-gap: 6rem;
  }
  .goal_card {
    grid-column: span 2;
    width: 40rem;
  }

  .heading_h2 {
    font-size: 8rem;
    padding-bottom: 6rem;
    grid-column: span 2;
  }
}
@media (max-width: 703px) {
  .container {
    grid-template-columns: 1fr;
    padding: 20rem 10rem 0 10rem;
  }
  .goal_card {
    grid-column: unset;
  }
  .card {
    width: 40rem;
  }
  .heading_h2 {
    font-size: 8rem;
    padding-bottom: 6rem;
    grid-column: span 1;
    text-align: center;
  }
}
@media (max-width: 599px) {
  .container {
    grid-template-columns: 1fr;
    padding: 20rem 5rem 0 8rem;
  }
  .card {
    width: 30rem;
  }
  .heading_h2 {
    font-size: 6.4rem;
  }
}
@media (max-width: 459px) {
}
